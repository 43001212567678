import React from 'react'
import { Link } from 'gatsby'
import Quarter from '../../images/quarter-sheet.jpg'

class Subcategorylisting extends React.Component {
	render() {
		const listings = this.props.listings.subcategories || []
		return (
			<div>
				<div className="row featured-items">
					{listings.map((list, i, key) => (
						<div className="col-md-6 col-12" key={list.name}>
							<div className="product-item">
								<div className="product-image">
									<img
										alt="Quarter"
										src={list.embed ? list.embed : Quarter}
										className="img-fluid"
									/>
								</div>
								<div className="product-details">
									<h4 className="price">
										<strong>{list.name}</strong>
									</h4>
								</div>
								<Link to={list.url} key={list.id} title={list.name}>
									<button
										className="btn btn-primary btn-block blue-btn-arrow shop-btn"
										type="button"
									>
										See Products
										<i className="fa fa-arrow-right" />
									</button>
								</Link>
							</div>
						</div>
					))}
				</div>
			</div>
		)
	}
}

export default Subcategorylisting
