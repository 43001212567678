import React from 'react'
import { Link } from 'gatsby'
import SideSubNavCat from './subcategory.js'
import { StaticQuery, graphql } from 'gatsby'

class SideNavCat extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			checkedItems: [],
		}
	}

	handleClick(e) {
		e.persist()
		console.log(e)
		console.log(e.target.checked)
		if (e.target.checked) {
			this.setState(prevState => ({
				checkedItems: [...prevState.checkedItems, e.target.id],
			}))
		} else {
			this.setState(prevState => ({
				checkedItems: prevState.checkedItems.filter(
					item => item !== e.target.id
				),
			}))
		}
	}
	render() {
		const categories = this.props.categories || []
		// console.log(this.props.categories.id)
		// console.log(this.state.category)
		// console.log(categories)
		return (
			<div className="categories">
				<ul className="categories-menu">
					{categories.map((category, key) => (
						<li
							className="has-children"
							key={category.id + category.name}
							data-id={category.id}
						>
							{category.subcategories.length > 0 && (
								<input
									type="checkbox"
									onClick={e => this.handleClick(e)}
									key={category.name}
									id={category.name}
									name={category.name}
								/>
							)}

							<label data-id={category.id}>
								<Link to={`/${category.url}`} title={category.name}>
									{category.name}
								</Link>

								<ul
									hidden={
										this.state.checkedItems.includes(`${category.name}`)
											? false
											: true
									}
								>
									<li key={category.id}>
										<SideSubNavCat categories={category} />
									</li>
								</ul>
							</label>
						</li>
					))}
					{/*					<li>
						<label>
							<Link to="/">Gift Certificate</Link>
						</label>
					</li>*/}
				</ul>
			</div>
		)
	}
}

// export default SideNavCat

const SideNavComponent = () => (
	<StaticQuery
		query={graphql`
			query {
				allStrapiCategories(sort: { fields: id, order: ASC }) {
					edges {
						node {
							id
							name
							url
							subcategories {
								name
								url
							}
						}
					}
				}
			}
		`}
		render={data => (
			<div>
				<SideNavCat
					categories={data.allStrapiCategories.edges.map(
						category => category.node
					)}
				/>
			</div>
		)}
	/>
)

export default SideNavComponent
