import React from 'react'

class Subcategorylisting extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			products: [],
		}
	}
	componentDidMount() {
		fetch(
			`https://buckwoodcraft.webriqshops.com/index.php?rt=a/product/filter&category_id=${
				this.props.content.productid
			}&api_key=nXWn7X9QR3Uo0hOjhKEoFRqntyxQHnac`
		)
			.then(response => response.json())
			.then(data => {
				this.setState({
					products: data.rows.map(product => {
						return { id: product.id, ...product.cell }
					}),
				})
			})
	}

	render() {
		// ${process.env.SHOP_API_KEY}
		const { products } = this.state
		const producturl =
			'https://buckwoodcraft.webriqshops.com/index.php?rt=product/product&product_id='
		// console.log(this.props.content)
		return (
			<div className="row featured-items">
				{products.map(product => (
					<div className="col-md-6" key={product.id}>
						<div className="product-item">
							<div className="product-image">
								<a
									href={producturl + product.id}
									alt="Specific Product Shop Image Url"
								>
									<img
										src={product.thumb}
										className="img-fluid"
										alt="product listing"
									/>
								</a>
							</div>
							<div className="product-details">
								<h4 className="price">${product.price}</h4>
								<span
									dangerouslySetInnerHTML={{
										__html: product.name,
									}}
								/>
							</div>
							<a href={producturl + product.id} alt="Specific Product Shop Url">
								<button
									className="btn btn-primary btn-block blue-btn shop-btn"
									type="button"
								>
									<strong>SHOP</strong>
								</button>
							</a>
						</div>
					</div>
				))}
			</div>
		)
	}
}

export default Subcategorylisting
