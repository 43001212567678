import React from 'react'
import { Link } from 'gatsby'

class SideNavSubCat extends React.Component {
	render() {
		return (
			<div>
				{this.props.categories.subcategories.map((subcategory, key) => (
					<Link
						to={subcategory.url}
						key={subcategory.id}
						title={subcategory.name}
					>
						{subcategory.name}
					</Link>
				))}
			</div>
		)
	}
}

export default SideNavSubCat
