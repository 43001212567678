import React from 'react'
// import SideNavCat from '../sidenav'
// import { StaticQuery, graphql } from 'gatsby'
import CategoryListing from '../listings/subcategorylisting'
import ProductListing from '../listings/productlisting'

const CategoryContent = ({ content }) => (
	<div className="col-md-8 shop-items">
		{(content.subcategories.length > 0 && (
			<CategoryListing listings={content} />
		)) || <ProductListing content={content} />}
	</div>
)

export default CategoryContent
