import React from 'react'
import Layout from '../layouts'
import { Link } from 'gatsby'
import CategoryContent from '../components/categorycontent/categorycontent.js'
import SideNavCat from '../components/sidenav'

class CategoryPage extends React.Component {
	render() {
		return (
			<Layout
				head={{
					name: this.props.data.strapiCategories.name,
					catURL: '/' + this.props.data.strapiCategories.url + '/',
				}}
			>
				<div className="page-heading">
					<div className="container">
						<h1>Shop</h1>
					</div>
				</div>
				<div className="shop-path">
					<div className="container">
						<span>
							<Link to="/" title="Home">
								Shop
							</Link>
							&nbsp;
							<i className="fa fa-angle-right" />
							{this.props.data.strapiCategories.name}
						</span>
					</div>
				</div>
				<div className="featured-section category-page page-content">
					<div className="container">
						<div className="row">
							<div className="col-md-4 shop-categories">
								<h4 className="section-heading">Categories</h4>
								<SideNavCat />
							</div>
							<CategoryContent content={this.props.data.strapiCategories} />
						</div>
						<div className="pt-5">
							<div
								dangerouslySetInnerHTML={{
									__html: this.props.data.strapiCategories.description,
								}}
							/>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default CategoryPage

export const categorynodeQuery = graphql`
	query($id: String!) {
		strapiCategories(id: { eq: $id }) {
			id
			name
			url
			description
			productid
			subcategories {
				name
				url
				id
				productid
				embed
			}
		}
	}
`
